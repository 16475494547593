<template>
  <div class="guide-cnt4">
    <h6 class="h6">配送方式</h6>
    <div class="cnt">
      <p class="p">1、快递配送：</p>
      <p class="p">出货当天即寄出，预计3-5天送达；</p>
      <p class="p">2、第三方物流：</p>
      <p class="p">大重量订单由物流公司派送；</p>
      <p class="p">3、加急快递：</p>
      <p class="p">由顺丰配送，客户需补快递费用差价或顺丰到付。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt4',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt4 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:10px;
      &:nth-child(2n+2) {
        margin-bottom:30px;
      }
    }
  }
}
</style>
